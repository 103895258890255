import React, { Component } from "react"
import { graphql } from "gatsby"
import ContactFooter from "../components/contactfooter"
import SEO from "../components/seo"
import AOS from 'aos'; 
import Typed from "react-typed"
import ScroolTop from '../components/scrolltop'

class Aboutus extends Component {
  componentDidMount() {
    const isBrowser = typeof document !== "undefined";
    const AOS = isBrowser ? require("aos") : undefined;

    this.aos = AOS;
    this.aos.init();
}
  componentWillReceiveProps (){ 
    AOS.refresh(); 
  }
  render() {
    const data = this.props.data
    const banner = data.wordpressPage.acf
    const pagedata = data.allWordpressPage.edges[0].node.acf.content_modules_page
    var oldString = banner.banner_blink_word;
    var mynewarray=oldString.split(',')
    return (
      <>
        <SEO title="About us" />
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <h5><span className="line"></span>{banner.banner_heading}</h5> */}
                <h1>
                  {banner.banner_content}{" "}
                  <Typed
                    strings={mynewarray}
                    typeSpeed={120}
                    backSpeed={50}
                    cursorChar={"_"}
                    loop
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <section className="about-page">
          <div className="about-top-wrap">
            <div className="container">
              <div className="top-content-wraper">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="top-content">
                      <p>{pagedata[0].lrcwfwi_col1}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="top-image">
                <img src={pagedata[0].lrcwfwi_full_width_image.source_url} alt="About full width" />
              </div>
            </div>
          </div>
          <div className="f-vision-wraper">
            <div className="container">
              <div className="vision-inner">
                <div className="title">
                  <h2>{pagedata[1].fwc_heading}</h2>
                </div>
                <div className="desc">
                  <p>{pagedata[1].fwc_content}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="f-values-wraper">
            <div className="container">
              <div className="title">
                <h2>{pagedata[2].v_sec_heading}</h2>
              </div>
              <div className="values-inner-wrap">
                <div className="row fv-row">
                  {pagedata[2].v_sec_values.map((node, index) => (
                    <div
                      key={index}
                      className={
                        index % 2
                          ? "col-md-6 col-lg-6 col-xl-3 values-item item-top"
                          : "col-md-6 col-lg-6 col-xl-3 values-item item-bottom"
                      }
                    >
                      <div className=" values-item-inner">
                        <div className="values-image-holder">
                          <div
                            className="values-image-inner"
                            data-aos="fade-right"
                            data-aos-duration="0"
                            data-aos-delay="300"
                            data-aos-easing="linear"
                          >
                            <img src={node.value_icon.source_url} alt="value box" />
                          </div>
                          <div className="values-content">
                            <div className="fv-number">
                              {node.value_index_no}
                            </div>
                            <h2 className="fv-title">{node.value_title}</h2>
                            <p className="fv-text">{node.value_content}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="f-goal-wraper">
            <div className="container">
              <div className="title">
                <h2>{pagedata[3].top_sec_heading}</h2>
              </div>
              <div className="goal-content-wrap">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="text">
                      <p>{pagedata[3].top_sec_left_content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactFooter />
        <ScroolTop />
      </>
    )
  }
}

export default Aboutus

export const query = graphql`
  {
    wordpressPage(wordpress_id: { eq: 11 }) {
      acf {        
        banner_heading
        banner_content
        banner_blink_word
      }
    }
    allWordpressPage(filter: { wordpress_id: { eq: 11 } }) {
      edges {
        node {
          acf {
            content_modules_page {
              ... on WordPressAcf_full_width_image_with_left_right_content {
                lrcwfwi_col1
                lrcwfwi_full_width_image {
                  source_url
                }
              }
              ... on WordPressAcf_full_width_content {                
                fwc_heading
                fwc_content
              }
              ... on WordPressAcf_values_section {                
                v_sec_heading
                v_sec_values {
                  value_index_no
                  value_title
                  value_icon {
                    source_url
                  }
                  value_content
                }
              }
              ... on WordPressAcf_top_section_with_left_right_content {
                top_sec_heading
                top_sec_left_content
                top_sec_right_content
              }
            }
          }
        }
      }
    }
  }
`
